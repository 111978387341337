<div class="header">
    <table>
        <tr>
            <td class="logo-cell">
                <div>
                  <img [src]="branding_logo" class="logo" alt="">
                </div>
            </td>
            <td class="search-cell" [ngClass]="{'disabled': show_confirm_modal || show_user_modal}">
                <div class="search-block">
                    <div class="search-wrapper">
                        <input type="text" class="search-box" placeholder="Search..." name="search" [(ngModel)]="search_value" (input)="search()" maxlength="100" search>
                        <button type="button" class="search-button" (click)="search()"><i class="fa fa-search" aria-hidden="true"></i></button>
                    </div>
                </div>
            </td>
            <td class="profile-cell" [ngClass]="{'disabled': show_confirm_modal || show_user_modal || show_reset_password_modal}">
                <div class="greeting">
                    Welcome, <span class="bold margin-left-5">{{name.slice(0, 10)}}</span>!
                </div>
                <span (click)="resetPassword()" class="logout-btn reset-password-btn">Reset Password</span>
                <span (click)="signOut()" class="logout-btn">Logout</span>
            </td>
        </tr>
    </table>
</div>

<div class="tab-main" [ngClass]="{'disabled': show_confirm_modal || show_user_modal || show_reset_password_modal}">
    <div class="configuration-page">
        <div class="tablewrapper">        
            <div class="table-responsive">            
                <p-table responsiveLayout="scroll" [value]="filtered_users" [scrollable]="true" scrollHeight="400px">
            
                    <ng-template pTemplate="header" class="center-header">
                        <tr class="table-header">
                            <th class="min-max-width-index">No.</th>
                            <th class="padding-left-10 min-max-width">User Name</th>
                            <th class="min-max-width-email">Email</th>
                            <th class="min-max-width">Role</th>
                            <th class="min-max-width">Region</th>
                            <th class="min-max-width-edit-delete">Edit</th>
                            <th>Delete</th>
                        </tr>
                    </ng-template>
            
                    <ng-template pTemplate="body" class="center-header" let-rowData="rowData" let-user sortMode="multiple">
            
                        <tr>
                            <td class="min-max-width-index">
                                {{ user.index }}
                            </td>
                            <td class="padding-left-10 min-max-width">
                                <div type="button" (click)="editUser(user)" class="user-name">{{ user.user_name ? user.user_name :
                                    "N/A" }}</div>
                            </td>
                            <td class="min-max-width-email">
                                <div type="button" (click)="editUser(user)" class="user-name">{{ user.email ? user.email : "N/A" }}
                                </div>
                            </td>
                            <td class="min-max-width">
                                {{ user.role ? user.role : "N/A" }}
                            </td>
                            <td class="min-max-width">
                                {{ user.vpn ? user.vpn : "N/A" }}
                            </td>
                            <td class="min-max-width-edit-delete">
                                <div class="btn options-btn edit-icon-color" (click)="editUser(user)"><i class="fa fa-pencil"></i></div>
                            </td>
                            <td class="min-max-width-edit-delete">
                                <div class="btn options-btn edit-icon-color" (click)="deleteUser(user)" [ngClass]="{'disabled': email === user.email && user.role === 'Admin'}"><i class="fa fa-trash"></i></div>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>            
            </div>        
        </div>

        <div class="center-align">
            <button class="btn-add" type="button" (click)="addUser()">Create New User</button>
        </div>
    </div>
</div>


<app-confirm-modal *ngIf="show_confirm_modal" (modalClose)="closeModal($event)" (addAction)="reloadHome($event)" [user_data]="user_data"></app-confirm-modal>
<app-add-edit-user-modal *ngIf="show_user_modal" (modalClose)="closeModal($event)" (addAction)="reloadHome($event)" [user_data]="user_data"></app-add-edit-user-modal>
<app-admin-reset-password *ngIf="show_reset_password_modal" (modalClose)="closeModal($event)" (resetModalClose)="closeResetModal($event)"></app-admin-reset-password>
<app-notification-bar *ngIf="error" (notificationClose)="notificationClose()" [message]="message" [type]="type"></app-notification-bar>

<ngx-spinner bdColor="rgba(255, 255, 255, 0.9)" size="large" color="#007741" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: green">Loading...</p>
</ngx-spinner>
