<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="login-row">
        <div class="login-container">


          <div class="wrapper-middle">
            <div class="login-box">
              <img [src]="branding_logo" class="logo" alt="">

              <div class="login-form">
                <div class="ng-dirty ng-valid-parse ng-invalid ng-invalid-required">
              
                  <input type="text" class="ng-dirty ng-valid-parse ng-empty ng-invalid ng-invalid-required ng-touched"
                    name="verificationCode" placeholder="Enter Verification Code" [(ngModel)]="user.code" maxlength="6">
              
                  <input type="email" class="ng-dirty ng-valid-parse ng-empty ng-invalid ng-invalid-required ng-touched email-input"
                    name="userName" placeholder="Enter Email Address" [(ngModel)]="user.email" [disabled]="user.email" maxlength="60">
              
                  <div class="position-relative">
                    <input class="ng-dirty ng-valid-parse ng-empty ng-invalid ng-invalid-required ng-touched" type="password"
                      name="password" [(ngModel)]="newPassword" (ngModelChange)="newPassword = $event" placeholder="Enter New Password" maxlength="30">
                    <span class="eye-icon">
                      <i class="fa fa-eye-slash" aria-hidden="true" (click)="showNewPassword($event.target)"></i>
                    </span>
                  </div>
              
                  <div class="position-relative">
                    <input class="ng-dirty ng-valid-parse ng-empty ng-invalid ng-invalid-required ng-touched" type="password"
                      name="confirm_password" [(ngModel)]="confirm_password" (ngModelChange)="confirm_password = $event"
                      placeholder="Enter Confirm Password" maxlength="30">
                    <span class="eye-icon">
                      <i class="fa fa-eye-slash" aria-hidden="true" (click)="showConfirmPassword($event.target)"></i>
                    </span>
                  </div>
              
                  <input type="submit" style="width: 280px;" value="Reset Password" (click)="resetPasswordCliked()">
                </div>
              </div>

              <div class="after-links">
                <a href="#/sign-in" >Back To Login</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<app-notification-bar *ngIf="error" (notificationClose)="notificationClose()" [message]="message" [type]="type"></app-notification-bar>

<ngx-spinner bdColor="rgba(255, 255, 255, 0.9)" size="large" color="#007741" type="ball-spin-clockwise">
  <p style="font-size: 20px; color: green">Loading...</p>
</ngx-spinner>