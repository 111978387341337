<div [ngSwitch]="mode">
  <div *ngSwitchCase="'Edit'">
    <div class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="lb-title">Edit User</div>
          <label for="modal" class="close" (click)="deny()">✖</label>
        </div>
        <div class="modal-body">
          <div class="no-ng-hide-animate">
            <div class="row" *ngIf="show_mirror">
              <div class="col-md-6">
                <div class="pt-4">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" (change)="mirrorChange($event)" [(ngModel)]="is_mirror">
                    <label class="form-check-label switch-lebel" for="flexSwitchCheckDefault">Add Mirror User</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="pt-4">
                  <span class="input-label">Email Address</span>
                  <input type="email" class="long-input margin-bottom-10" style="min-width: 562px;"
                    [(ngModel)]="user.email" name="Email" placeholder="Enter Email Address" [disabled]="mode === 'Edit'" maxlength="60"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="pt-4">
                  <span class="input-label">User Name</span>
                  <input type="text" class="long-input margin-bottom-10" style="min-width: 267px;"
                    [(ngModel)]="user.user_name" name="UserName" placeholder="Enter User Name" [disabled]="is_mirror" maxlength="20"/>
                </div>
              </div>
              <div class="col-md-6 input-width pt-4">
                <div>
                  <span class="input-label">Role</span>
                  <div>
                    <select class="vpn-dropdown" id="role" name="role" (change)="roleChange($event)" [(ngModel)]="user.role" [disabled]="(user_email === user_data.email && user_data.role === 'Admin' && mode === 'Edit') || is_mirror">
                      <option *ngFor="let location of user_role" [ngValue]="location">
                        {{ location }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>          
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="pt-4">
                  <span class="input-label">Password</span>
                  <span *ngIf="mode === 'Edit' && role === 'nonAdmin'" class="change-password-container">
                    <input type="checkbox" id="changePasswordCheckbox" (change)="roleChange($event)" [(ngModel)]="change_password" [disabled]="is_mirror" maxlength="30"/>
                    <label for="changePasswordCheckbox" class="change-password-label">Change Password</label>
                  </span>
                  <div class="position-relative">
                    <input type="password" class="long-input margin-bottom-10" style="min-width: 267px;"
                      [(ngModel)]="user.password" name="password" placeholder="Enter Password" [disabled]="(mode === 'Edit' && (!change_password || role === 'Admin')) || is_mirror" maxlength="30"/>
                    <span class="eye-icon">
                      <i class="fa fa-eye-slash" aria-hidden="true" (click)="togglePasswordVisibility($event.target)"></i>
                    </span>
                  </div>
                </div>
              </div> 
              <div class="col-md-6 input-width pt-4">
                <div>
                  <span class="input-label">Region</span>
                  <div>
                    <select class="vpn-dropdown" id="vpn" name="vpn" [(ngModel)]="user.vpn" [disabled]="is_mirror">
                      <option *ngFor="let location of vpn" [ngValue]="location">
                        {{ location }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div class="modal-footer">
          <div class="lb-button-wrap">
            <button type="button" class="close-lb light-btn" (click)="deny()">Cancel</button>
            <button class="add-btn" (click)="is_mirror ? addMirror() : updateUser()">{{ is_mirror ? 'Add Mirror' : 'Edit'}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchDefault>
    <div class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="lb-title">Add User</div>
          <label for="modal" class="close" (click)="deny()">✖</label>
        </div>
        <div class="modal-body">
          <div class="no-ng-hide-animate">
            <div class="row">
              <div class="col-md-6">
                <div class="pt-4">
                  <span class="input-label">Email Address</span>
                  <input type="email" class="long-input margin-bottom-10" style="min-width: 562px;"
                    [(ngModel)]="user.email" name="Email" placeholder="Enter Email Address" [disabled]="mode === 'Edit'" maxlength="60"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="pt-4">
                  <span class="input-label">User Name</span>
                  <input type="text" class="long-input margin-bottom-10" style="min-width: 562px;"
                    [(ngModel)]="user.user_name" name="UserName" placeholder="Enter User Name" maxlength="20"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 input-width pt-4">
                <div>
                  <span class="input-label">Role</span>
                  <div>
                    <select class="vpn-dropdown" style="min-width: 562px;" id="role" name="role" (change)="roleChange($event)" [(ngModel)]="user.role" [disabled]="user_email === user_data.email && mode === 'Edit'">
                      <option *ngFor="let location of user_role" [ngValue]="location">
                        {{ location }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="pt-4">
                  <span class="input-label">Password</span>
                  <div class="position-relative">
                    <input type="password" class="long-input margin-bottom-10" style="min-width: 562px;"
                      [(ngModel)]="user.password" name="password" placeholder="Enter Password" [disabled]="mode === 'Edit' && (!change_password || role === 'Admin')" maxlength="30"/>
                    <span class="eye-icon icon-left">
                      <i class="fa fa-eye-slash" aria-hidden="true" (click)="togglePasswordVisibility($event.target)"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div class="modal-footer">
          <div class="lb-button-wrap">
            <button type="button" class="close-lb light-btn" (click)="deny()">Cancel</button>
            <button class="add-btn" (click)="addUser()">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-notification-bar *ngIf="error" (notificationClose)="notificationClose()" [message]="message" [type]="type"></app-notification-bar>

<ngx-spinner bdColor="rgba(255, 255, 255, 0.9)" size="large" color="#007741" type="ball-spin-clockwise">
  <p style="font-size: 20px; color: green">Loading...</p>
</ngx-spinner>