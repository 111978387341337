<div class="modal confirm">
    <div class="modal-content">
      <div class="modal-header confirm">
        <div class="lb-title text-center">Delete User</div>
        <label for="modal" class="close" (click)="deny()">✖</label>
      </div>
      <div class="modal-body">
        <div class="no-ng-hide-animate">
            <div class="message-body text-center">Are you sure want to remove this user</div>
        </div>     
     </div>
      
      <div class="modal-footer confirm">
        <div class="lb-button-wrap">
            <button type="button" class="close-lb light-btn" (click)="deny()">Cancel</button>
            <button  class="delete-btn" (click)="remove()">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <app-notification-bar *ngIf="error" (notificationClose)="notificationClose()" [message]="message" [type]="type"></app-notification-bar>

  <ngx-spinner bdColor="rgba(255, 255, 255, 0.9)" size="large" color="#007741" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: green">Loading...</p>
  </ngx-spinner>
