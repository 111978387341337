<div class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <div class="lb-title">Reset Password</div>
        <label for="modal" class="close" (click)="deny()">✖</label>
      </div>
      <div class="modal-body">
        <div class="no-ng-hide-animate">
          <div class="row">
            <div class="col-md-6">
              <div class="pt-4">
                <span class="input-label lebel">Old Password</span>
                <div class="position-relative">
                  <input type="password" class="long-input margin-bottom-10" class="password-field"
                    [(ngModel)]="user.old_password" name="old_password" placeholder="Enter Old Password" maxlength="30"/>
                  <span class="eye-icon eye-margin">
                    <i class="fa fa-eye-slash" aria-hidden="true" (click)="showOldPassword($event.target)"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="pt-4">
                <span class="input-label lebel">New Password</span>
                <div class="position-relative">
                  <input type="password" class="long-input margin-bottom-10" class="password-field"
                    [(ngModel)]="user.new_password" name="new_password" placeholder="Enter New Password" maxlength="30"/>
                  <span class="eye-icon eye-margin">
                    <i class="fa fa-eye-slash" aria-hidden="true" (click)="showNewPassword($event.target)"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="pt-4">
                <span class="input-label lebel">Confirm Password</span>
                <div class="position-relative">
                  <input type="password" class="long-input margin-bottom-10" class="password-field"
                    [(ngModel)]="user.confirm_password" name="confirm_password" placeholder="Enter Confirm Password" maxlength="30"/>
                  <span class="eye-icon eye-margin">
                    <i class="fa fa-eye-slash" aria-hidden="true" (click)="showConfirmPassword($event.target)"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="modal-footer">
        <div class="lb-button-wrap">
          <button type="button" class="close-lb light-btn" (click)="deny()">Cancel</button>
          <button class="add-btn" (click)="resetPasswordCliked()">Update</button>
        </div>
      </div>
    </div>
  </div>
  
  <app-notification-bar *ngIf="error" (notificationClose)="notificationClose()" [message]="message" [type]="type"></app-notification-bar>
  
  <ngx-spinner bdColor="rgba(255, 255, 255, 0.9)" size="large" color="#007741" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: green">Loading...</p>
  </ngx-spinner>