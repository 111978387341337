import { Component, OnInit } from '@angular/core';
import { forgetPassword } from '../../models/forget-password.model';
import { Router } from '@angular/router';
import { CognitoService } from '../../services/cognito.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfig } from 'src/app/models/config';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  user: forgetPassword = {
    email: '',
    code: ''
  };

  error: boolean = false;

  branding_logo: any = '';
  newPassword: string = '';
  confirm_password: string = '';
  message: string = '';
  type: string = '';

  constructor(private router: Router,
    private cognitoService: CognitoService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private notification: NotificationService) { }

  ngOnInit(): void {
    if (window.history.state.navigationId === 1) {
      this.router.navigate(['/sign-in']);
    }
    this.spinner.show();
    this.getDefaultLogo();
    this.getEmail();
    this.showNotifications("Your 6 digit confirmation code sent to your entered email address", 'success');
  }

  getDefaultLogo() {
    this.apiService.getLogo(AppConfig.s3_base_endpoint + AppConfig.public_bucket_name + "/company_logos/VerifiedFirstLogo.png", "image/png").subscribe(
      (response) => {
        const blobUrl = URL.createObjectURL(response);
        this.branding_logo = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.message = error.message;
        this.type = 'error';
        this.notification.showError(this.type);
      }
    );
  }

  public getEmail() {
    this.apiService.currentValue.subscribe(value => {
      this.user.email = value;
    });
  }

  showNewPassword(iconElement: any) {
    const passwordInput = document.querySelector('input[name="password"]');

    if (passwordInput && iconElement) {
      if (passwordInput.getAttribute('type') === 'password') {
        passwordInput.setAttribute('type', 'text');
        iconElement.classList.remove('fa-eye-slash');
        iconElement.classList.add('fa-eye');
      } else {
        passwordInput.setAttribute('type', 'password');
        iconElement.classList.remove('fa-eye');
        iconElement.classList.add('fa-eye-slash');
      }
    }
  }

  showConfirmPassword(iconElement: any) {
    const passwordInput = document.querySelector('input[name="confirm_password"]');

    if (passwordInput && iconElement) {
      if (passwordInput.getAttribute('type') === 'password') {
        passwordInput.setAttribute('type', 'text');
        iconElement.classList.remove('fa-eye-slash');
        iconElement.classList.add('fa-eye');
      } else {
        passwordInput.setAttribute('type', 'password');
        iconElement.classList.remove('fa-eye');
        iconElement.classList.add('fa-eye-slash');
      }
    }
  }

  public async resetPasswordCliked() {
    try {
      this.spinner.show();
      this.validUserData();

      if (this.error) {
        this.spinner.hide();
        return;
      }

      let data = {
        email: this.user.email,
        password: this.newPassword
      };

      const observable = await this.apiService.checkPasswordDuplication(data);
      const result = await observable.toPromise();

      if (!result) {
        this.spinner.hide();
        this.showNotifications('User Validation Failed', 'error');
      }

      if (result.status === 200) {
        this.spinner.hide();
        this.newPasswordSubmit();
      }
      else {
        this.spinner.hide();
        this.showNotifications('Can not use last three used passwords, please create a new password', 'error');
      }
    } catch (error: any) {
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }

  newPasswordSubmit() {
    this.spinner.show();
    this.validUserData();

    if (this.error) {
      this.spinner.hide();
      return;
    }

    if (this.user && this.user.code && this.newPassword.trim().length !== 0) {
      const { email, code } = this.user;
      const newPassword = this.newPassword.trim();
      this.cognitoService.forgetPasswordSubmit({ username: email, confirmationCode: code, newPassword })
        .then(() => {
          this.changePassword();
        })
        .catch((error: any) => {
          this.spinner.hide();
          this.showNotifications(error.message, 'error');
        })
    } else {
      this.spinner.hide();
      this.showNotifications("Please enter valid code and password", 'warning');
    }
  }

  public async changePassword() {
    try {
      const email = this.user.email;
      const newPassword = this.newPassword;
      const observable = await this.apiService.changePassword({ email: email, password: newPassword })
      const result = await observable.toPromise();
      if (result.status === 200) {
        this.spinner.hide();
        this.showNotifications("Password Reset Successfully", 'success');
        this.router.navigate(['/sign-in']);
      } else {
        this.spinner.hide();
        this.showNotifications(result.message, 'error');
      }
    } catch (error: any) {
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }

  cancelPasswordReset() {
    this.router.navigate(['/sign-in']);
  }

  validUserData() {
    this.error = false;

    if (!this.user.email) {
      this.showNotifications("Email Required", 'warning');
      return;
    }

    const check_email = this.user.email.match(/^[-_a-zA-Z0-9]+(?:[!#$%&'*.+=?^`{|}~][-_a-zA-Z0-9]+)*@((?:[-a-zA-Z0-9]+\.)+[a-zA-Z]{2,})$/);

    if (!check_email) {
      this.showNotifications("Incorrect Email", 'warning');
      return;
    }

    if (!this.newPassword) {
      this.showNotifications("Password Required", 'warning');
      return;
    }

    if (this.newPassword.length < 12) {
      this.showNotifications("Password length should be more than or equal to 12", 'warning');
      return;
    }

    const password_regex = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%&*])[A-Za-z\d@#$%&*]*$/;

    if (!password_regex.test(this.newPassword)) {
      this.showNotifications("Password must include at least one upper case letter ('A-Z'), one lower case letter ('a-z'), one numeric value ('0-9') and one special character value ('@,#,$,%,&,*')", 'warning');
      return;
    }

    if (!this.confirm_password) {
      this.showNotifications("Confirm Password Required", 'warning');
      return;
    }

    if (this.newPassword !== this.confirm_password) {
      this.showNotifications("Password Do not match", 'warning');
      return;
    }
  }

  showNotifications(message: string, type: string) {
    this.error = true;
    this.message = message;
    this.type = type;
    if (this.type == 'error') {
      this.notification.showError(this.message);
    } else if (this.type === 'warning') {
      this.notification.showWarning(this.message)
    } else if (this.type === 'success') {
      this.notification.showSuccess(this.message)
    } else {
      this.notification.showError("An error occurred. Please try again.");
    }

  }

  notificationClose() {
    this.error = false;
  }

}
