import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit {

  @Input() message: string = '';
  @Input() type: string = '';
  @Output() notificationClose = new EventEmitter<true>();
  isVisible = false;

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
    this.notificationService.getNotificationObservable().subscribe(notification => {
      this.message = notification.message;
      this.type = notification.type;
      this.isVisible = true;
    });
    setTimeout(() => {
      this.isVisible = false;
      this.notificationClose.emit(true);
    }, 5000);
  }
}
