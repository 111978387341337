import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Amplify, type ResourcesConfig } from 'aws-amplify';
import { sessionStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { Router } from '@angular/router';
import { signIn, signOut, 
  type SignInInput, resetPassword, 
  type ResetPasswordOutput, confirmResetPassword,
  type ConfirmResetPasswordInput, getCurrentUser, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { updatePassword, type UpdatePasswordInput } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})

export class CognitoService {

  private authenticationSubject: BehaviorSubject<any>;

  authConfig: ResourcesConfig['Auth'] = {
    Cognito: {
      userPoolId: environment.cognito.userPoolId,
      userPoolClientId: environment.cognito.userPoolWebClientId
    }
  }

  constructor(private router: Router,  
    private spinner: NgxSpinnerService,
    ) {
    Amplify.configure({
      Auth: this.authConfig
    });

    cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  async signIn({ username, password }: SignInInput) {
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      return { isSignedIn, nextStep };
    } catch (error:any) {
      throw error;
    }
  }

  async currentAuthenticatedUser(): Promise<{ username: string }> {
    try {
      const { username } = await getCurrentUser();
      return { username };
    } catch (err) {
      console.log(err);
      return { username: '' };
    }
  }

  async getUserdata(): Promise<{email:any}> {
    let email: any;

    try {
      const { email} = await fetchUserAttributes();
      return { email } 
    } catch (err) {
      return { email }
    }
  }

  async currentSession(): Promise<{ rawIdToken?: string }> {
    try {
      const { tokens } = await fetchAuthSession({ forceRefresh: true });
      const rawIdToken = tokens?.idToken?.toString();
      return { rawIdToken };
    } catch (err) {
      console.error(err);
      return { rawIdToken: undefined };
    }
  }  
  
  async signOut() {
    try {
      await signOut({ global: true });

      this.spinner.hide();
      this.authenticationSubject.next(false);
      this.router.navigate(['/sign-in']);
    } catch (error:any) {
      throw error;
    }
  }

  async forgetPassword(username: any) {
    try {
      const output = await resetPassword({ username });
      if (output) {
        this.handleResetPasswordNextSteps(output);
      } else {
        console.error("Something went wrong, try again");
        this.router.navigate(['/sign-in']);
      }
    } catch (error:any) {
      throw error;
    }
  }

  handleResetPasswordNextSteps(output: ResetPasswordOutput) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`);
        break;
      case 'DONE':
        break;
    }
  }

  async forgetPasswordSubmit({
    username,
    confirmationCode,
    newPassword
  }: ConfirmResetPasswordInput) {
    try {
      return await confirmResetPassword({ username, confirmationCode, newPassword });
    } catch (error) {
      throw error;
    }
  }

  async handleUpdatePassword({
    oldPassword,
    newPassword
  }: UpdatePasswordInput) {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.currentAuthenticatedUser()
        .then((user: any) => {
          return !!user.username;
        })
        .catch(() => {
          return false;
        });
    }
  }
  

}
