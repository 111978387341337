import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from '../models/config';
import { CognitoService } from './cognito.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private sharedValue = new BehaviorSubject<string>('');
  currentValue = this.sharedValue.asObservable();

  constructor(private http: HttpClient, private cognitoService: CognitoService) {}

  getLogo(url: string, type: string): Observable<Blob> {
    const config = {
      responseType: 'arraybuffer' as const,
    };

    return this.http.get(url, config).pipe(
      map((response: ArrayBuffer) => {
        return new Blob([response], { type: type });
      }),
      catchError(this.handleError)
    );
  }

  async getUser(): Promise<Observable<any>> {
    try {
      const headers = await this.getHeadersWithAuthorization();
      const url = `${AppConfig.api_endpoint}/vpn`;
  
      return this.http.get(url, { headers }).pipe(
        catchError((error: any) => {
          console.error('Error in GET request:', error);
          return throwError(error);
        })
      );
    } catch (err) {
      console.error('Error getting headers:', err);
      return throwError(err);
    }
  }

  async checkPasswordDuplication(data:any): Promise<Observable<any>> {
    try {
      const token = environment.app_secret_key;
      const headers = await this.getHeadersWithAuthorization();
      const url = `${AppConfig.api_endpoint}/vpn/auth/check`;
  
      return this.http.put(url, { email: data.email, password: data.password, token: token}, { headers }).pipe(
        catchError((error: any) => {
          console.error('Error in GET request:', error);
          return throwError(error);
        })
      );
    } catch (err) {
      console.error('Error getting headers:', err);
      return throwError(err);
    }
  }

  async checkPasswordExpiry(data:any): Promise<Observable<any>> {
    try {
      const token = environment.app_secret_key;
      const headers = await this.getHeadersWithAuthorization();
      const url = `${AppConfig.api_endpoint}/vpn/auth/check`;
  
      return this.http.post(url, { email: data, token: token}, { headers }).pipe(
        catchError((error: any) => {
          console.error('Error in GET request:', error);
          return throwError(error);
        })
      );
    } catch (err) {
      console.error('Error getting headers:', err);
      return throwError(err);
    }
  }

  async getUserEmails(): Promise<Observable<any>> {
    try {
      const headers = await this.getHeadersWithAuthorization();
      const url = `${AppConfig.api_endpoint}/vpn/auth`;
  
      return this.http.get(url, { headers }).pipe(
        catchError((error: any) => {
          console.error('Error in GET request:', error);
          return throwError(error);
        })
      );
    } catch (err) {
      console.error('Error getting headers:', err);
      return throwError(err);
    }
  }
  
  async postUser(data: any): Promise<Observable<any>> {
    try {
      const headers = await this.getHeadersWithAuthorization();
      return this.http.post(`${AppConfig.api_endpoint}/vpn`, data, { headers }).pipe(
        catchError((error: any) => {
          console.error('Error in POST request:', error);
          return throwError(error);
        })
      );
    } catch (err) {
      console.error('Error getting headers:', err);
      return throwError(err);
    }
  }

  async putUser(data: any): Promise<Observable<any>> {
    try {
      const headers = await this.getHeadersWithAuthorization();
      return this.http.put(`${AppConfig.api_endpoint}/vpn`, data, { headers }).pipe(
        catchError((error: any) => {
          console.error('Error in PUT request:', error);
          return throwError(error);
        })
      );
    } catch (err) {
      console.error('Error getting headers:', err);
      return throwError(err);
    }
  }
  
  async deleteUser(data: any): Promise<Observable<any>> {
    try {
      const headers = await this.getHeadersWithAuthorization();
      const url = `${AppConfig.api_endpoint}/vpn?id=${data.id}&vpn=${data.vpn}`;
      return this.http.delete(url, { headers }).pipe(
        catchError((error: any) => {
          console.error('Error in DELETE request:', error);
          return throwError(error);
        })
      );
    } catch (err) {
      console.error('Error getting headers:', err);
      return throwError(err);
    }
  }

  async changePassword(data: any): Promise<Observable<any>> {
    try {
      const token = environment.app_secret_key;
      const headers = await this.getHeadersWithAuthorization();
      const url = `${AppConfig.api_endpoint}/vpn/auth`;
      return this.http.post(url, { email: data.email, password: data.password, token}, { headers }).pipe(
        catchError((error: any) => {
          console.error('Error in PATCH request:', error);
          return throwError(error);
        })
      );
    } catch (err) {
      console.error('Error getting headers:', err);
      return throwError(err);
    }
  }

  async resetPassword(data: any): Promise<Observable<any>> {
    try {
      const headers = await this.getHeadersWithAuthorization();
      const url = `${AppConfig.api_endpoint}/vpn/auth`;
      return this.http.put(url, data, { headers }).pipe(
        catchError((error: any) => {
          console.error('Error in PATCH request:', error);
          return throwError(error);
        })
      );
    } catch (err) {
      console.error('Error getting headers:', err);
      return throwError(err);
    }
  }

  private async getHeadersWithAuthorization(): Promise<HttpHeaders> {
    try {
      const idTokenObject = await this.cognitoService.currentSession();
      const id_token = idTokenObject?.rawIdToken;
  
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${id_token}`,
      });
  
      return headers;
    } catch (err) {
      console.error('Error getting headers:', err);
      return new HttpHeaders();
    }
  }

  public changeValue(value: string) {
    this.sharedValue.next(value);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An error occurred';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error: ${error.status}, ${error.statusText || 'Unknown error'}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
