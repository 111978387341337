import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { HomeComponent } from './components/home/home.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AddEditUserModalComponent } from './components/add-edit-user-modal/add-edit-user-modal.component';
import { NotifierModule } from 'angular-notifier';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TableModule } from 'primeng/table';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { CognitoService } from './services/cognito.service';
import { ApiService } from './services/api.service';
import { NotificationService } from './services/notification.service';
import { AdminResetPasswordComponent } from './components/admin-reset-password/admin-reset-password.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    HomeComponent,
    ForgetPasswordComponent,
    ConfirmModalComponent,
    AddEditUserModalComponent,
    NotificationBarComponent,
    AdminResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NotifierModule,
    CommonModule,
    BrowserAnimationsModule,
    AmplifyAuthenticatorModule,
    NgxSpinnerModule,
    TableModule
  ],
  providers: [
    CognitoService,
    ApiService,
    NotificationService
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
