<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="login-row">
        <div class="login-container">


          <div class="wrapper-middle">
            <div class="login-box">
              <img [src]="branding_logo" class="logo" alt="">

              <div class="login-form">
                <div class="ng-dirty ng-valid-parse ng-invalid ng-invalid-required">

                  <input type="email" class="ng-dirty ng-valid-parse ng-empty ng-invalid ng-invalid-required ng-touched"
                    name="email" [(ngModel)]="user.email" (ngModelChange)="user.email = $event"
                    placeholder="Email Address" autocomplete="off" maxlength="60">

                    <div class="position-relative">
                      <input class="ng-dirty ng-valid-parse ng-empty ng-invalid ng-invalid-required ng-touched"
                        type="password" name="password" [(ngModel)]="user.password"
                        (ngModelChange)="user.password = $event" placeholder="Enter Password" maxlength="30">
                      <span class="eye-icon">
                        <i class="fa fa-eye-slash" aria-hidden="true" (click)="togglePasswordVisibility()"></i>
                      </span>
                    </div>
                  <input type="submit" style="width: 280px;" value="Log In" (click)="signInClicked()">
                </div>
              </div>

              <div class="after-links">
                <button (click)="forgetPasswordClicked()" class="link-button">Forget Password?</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<app-notification-bar *ngIf="error" (notificationClose)="notificationClose()" [message]="message" [type]="type"></app-notification-bar>

<ngx-spinner bdColor="rgba(255, 255, 255, 0.9)" size="large" color="#007741" type="ball-spin-clockwise">
  <p style="font-size: 20px; color: green">Loading...</p>
</ngx-spinner>