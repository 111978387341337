import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input() user_data:any = ''
  @Output() modalClose = new EventEmitter<{value: boolean}>();
  @Output() addAction = new EventEmitter<{user: any}>();

  public message: string = '';
  public type: string = '';
  public error: boolean = false;

  constructor(private api: ApiService, 
    private spinner: NgxSpinnerService, 
    private router: Router, 
    private notification: NotificationService) {}

  ngOnInit(): void {
    this.error = false;
  }

  deny() {
    this.modalClose.emit({value:false});
  }

  public async remove() {
    try {
      this.spinner.show();
      const observable = await this.api.deleteUser(this.user_data)
      const result = await observable.toPromise();
      if (!result) {
        this.spinner.hide();
        this.showNotifications('User validation failed', 'error');
      }
      if (result.status === 200) {
        let message: string = result.message;
        let mode: string = 'Remove';
        this.reloadHome({ message: message, mode: mode });
        this.router.navigate(['/home']);
      } else {
        this.spinner.hide();
        this.showNotifications(result.message, 'error')
      }
    } catch (error: any) {
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }

  reloadHome(user: { message: string; mode: string}) {
    this.addAction.emit({user});
  }

  showNotifications(message: string, type: string) {
    this.error = true;
    this.message = message;
    this.type = type;
    if (this.type == 'error') {
      this.notification.showError(this.message);
    } else if (this.type === 'warning') {
      this.notification.showWarning(this.message)
    } else if (this.type === 'success') {
      this.notification.showSuccess(this.message)
    } else {
      this.notification.showError("An error occurred. Please try again.");
    }

  }

  notificationClose() {
    this.error = false;
  }
  

}
