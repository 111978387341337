import { Component, NgZone, OnInit } from '@angular/core';
import { CognitoService } from '../../services/cognito.service';
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfig } from 'src/app/models/config';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public user_list: any;
  
  public show_confirm_modal: boolean = false;
  public user_data: any = {};
  public show_user_modal: boolean = false;
  public branding_logo: any = '';
  public name: string = '';
  public email: string = '';
  public search_value: any = ''
  public filtered_users: any = [];
  public message: string = '';
  public type: string = '';
  public error: boolean = false;
  public show_reset_password_modal: boolean = false;
  public mode: string = '';
  public search_users: any[] = [];

  constructor (private cognitoService: CognitoService, 
    private apiService: ApiService, 
    private zone: NgZone, 
    private spinner: NgxSpinnerService, 
    private sanitizer: DomSanitizer,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.getDefaultLogo();
    this.getUserDetail();
    this.getUserList();   
  }

  getDefaultLogo() {
    this.apiService.getLogo(AppConfig.s3_base_endpoint + AppConfig.public_bucket_name + "/company_logos/VerifiedFirstLogo.png", "image/png").subscribe(
      (response) => {
        const blobUrl = URL.createObjectURL(response);
        this.branding_logo = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
      },
      (error) => {
        this.showNotifications(error.message, 'error');
      }
    );
  }

  search() {
    if (!this.search_value) {
      this.filterUser();
    } else {
      this.filtered_users = this.search_users.filter((user: { user_name: string, email: string, role: string, vpn: string }) =>
        user.user_name.toLowerCase().includes(this.search_value.toLowerCase()) || user.email.toLowerCase().includes(this.search_value.toLowerCase()) || user.role.toLowerCase().includes(this.search_value.toLowerCase()) || user.vpn.toLowerCase().includes(this.search_value.toLowerCase())
      );
    }
  }

  filterUser() {
    this.filtered_users = this.user_list.map((user: any, index: number) => ({
      ...user,
      index: index + 1
    }));

    this.search_users = this.filtered_users;
  }

  private async getUserDetail() {
    const authenticatedUser = await this.cognitoService.getUserdata();
    if (authenticatedUser && authenticatedUser.email) {
        this.email = authenticatedUser.email;
    }
  }

  private async getUserList() {
    this.spinner.show();
    this.search_value = '';
    this.user_list = [];
    try {
      const observable = await this.apiService.getUser();
      const result = await observable.toPromise();

      if (!result) {
        this.spinner.hide();
        this.showNotifications('No user found', 'error');
      }

      const data = result.body
      
      if (data.length) {
        this.spinner.hide();
        this.user_list = JSON.parse(data);
        this.filterUser();
        this.getName();
        this.showModalNotification();
      } else {
        this.spinner.hide();
        this.showNotifications(data.message, 'error');
      }
    } catch (error:any) {
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }

  getName() {
    const user = this.user_list.filter((u: { email: string; }) => u.email === this.email);
    this.name = user.length ? user[0].user_name : 'VF Admin';
  }
  
  editUser (index: any) {

    this.user_data = index;
    this.user_data.type = 'Edit';
    this.user_data.user_email = this.email;
    this.user_data.list = this.filtered_users;
    this.displayAddEditModal();
  }

  addUser () {

    this.user_data = {};
    this.user_data.type = 'Add';
    this.user_data.list = this.user_list;
    this.displayAddEditModal();
  }

  async signOut () {

    this.spinner.show();
    await this.cognitoService.signOut();
  }

  deleteUser (index: any) {

    if (this.email === index.email && index.role === 'Admin') {
      return;
    }

    this.displayConfirmModal(index);
  }

  private displayConfirmModal(data: any) {
    this.zone.run(() => {
      this.user_data = {id: data.id, vpn: data.vpn};
      this.show_confirm_modal = true;
    });
  }

  private displayAddEditModal() {
    this.zone.run(() => {
      this.show_user_modal = true;
    });
  }

  closeModal(value: any): void {
    this.show_user_modal = false;
    this.show_confirm_modal =false;
    this.show_reset_password_modal = false;
  }

  reloadHome(user:any): void {
    this.show_user_modal = false;
    this.show_confirm_modal =false;
    this.mode = user.user.mode;
    this.message = user.user.message;
    this.getUserList();
  }

  showModalNotification() {
    switch (this.mode) {
      case 'Edit':
        this.showNotifications(this.message, 'success');
        break;
      case 'Add':
        this.showNotifications(this.message, 'success');
        break;
      case 'Remove':
        this.showNotifications(this.message, 'success');
        break;
      default:
        break;
    }
  }

  async resetPassword() {
    this.show_reset_password_modal = true;
  }

  showNotifications(message: string, type: string) {
    this.error = true;
    this.message = message;
    this.type = type;
    if (this.type == 'error') {
      this.notification.showError(this.message);
    } else if (this.type === 'warning') {
      this.notification.showWarning(this.message)
    } else if (this.type === 'success') {
      this.notification.showSuccess(this.message)
    } else {
      this.notification.showError("An error occurred. Please try again.");
    }

  }

  notificationClose() {
    this.error = false;
  }

  closeResetModal(value:any) {
    this.show_reset_password_modal = false;
    this.getUserList();
    this.showNotifications("Password Successfully Update", 'success');
  }

}
