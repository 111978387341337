import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-edit-user-modal',
  templateUrl: './add-edit-user-modal.component.html',
  styleUrls: ['./add-edit-user-modal.component.scss'],

})
export class AddEditUserModalComponent {

  constructor(private api: ApiService, 
    private router: Router, 
    private spinner: NgxSpinnerService, 
    private notification: NotificationService) {}

  @Input() user_data: any = {};
  @Output() addAction = new EventEmitter<{user: any}>();
  @Output() modalClose = new EventEmitter<{value: boolean}>();

  public mode: string = '';
  public error: boolean = false;

  public vpn: any = ['Virginia','Tokyo'];
  public user_role: any = ['Admin','nonAdmin'];
  public user: any = {};
  public user_list: any = [];
  public user_email: string = '';
  public change_password: boolean = false;
  public message: string = '';
  public type: string = '';
  public show_password: boolean = false;
  public current_password: string = '';

  public role: string = '';
  public is_mirror: boolean = false;
  public show_mirror: boolean = false;

  ngOnInit(): void {
    this.mode = this.user_data.type;
    this.user_list = this.user_data.list;

    this.user = {
      id: this.user_data.id || '',
      user_name: this.user_data.user_name || '',
      password: this.user_data.password || '',
      vpn: this.user_data.vpn || 'Virginia',
      role: this.user_data.role || 'nonAdmin',
      email: this.user_data.email || '',
      user_email: this.user_data.user_email
    };

    this.role = this.user_data.role;
    this.user_email = this.user_data.user_email;
    this.current_password = this.user_data.password;
    this.show_mirror = this.checkShowMirror();
  }

  checkShowMirror(): boolean {
    const count = this.user_list.filter((u: any) => u.email === this.user.email);

    if (count.length < 2) {
      return true;
    }
    return false;
  }

  roleChange(event: any): void {
    this.role = this.user.role;
    if (this.role === 'Admin' && this.mode === 'Edit') {
      this.user.password = this.user_data.password;
    } else if (this.role === 'nonAdmin' && this.mode === 'Edit' && !this.change_password) {
      this.user.password = this.user_data.password;
    } else if (this.role === 'nonAdmin' && this.mode === 'Edit' && this.change_password) {
      this.user.password = '';
    } else {
      this.user.password = this.user_data.password;
    }
  }

  mirrorChange(event: any): void {
    if (this.is_mirror) {
      this.user = {
        id: this.user_data.id || '',
        user_name: this.user_data.user_name || '',
        password: this.user_data.password || '',
        vpn: this.user_data.vpn || 'Virginia',
        role: this.user_data.role || 'nonAdmin',
        email: this.user_data.email || ''
      };
    }
  }

  validForms() {
    this.error = false;

    if (this.mode === 'Edit' && !this.user.id) {
      this.showNotifications('User Id Required', 'warning')
      return;
    }

    if (!this.user.email) {
      this.showNotifications('Email Address Required', 'warning')
      return;
    }

    if (!this.user.role) {
      this.showNotifications("Role Required", 'warning');
      return;
    }

    if (this.mode === 'Edit' && !this.user.vpn) {
      this.showNotifications("Vpn Required", 'warning');
      return;
    }

    if (!this.user.user_name) {
      this.showNotifications("User Name Required", 'warning');
      return;
    }

    if (!this.user.password) {
      this.showNotifications("Password Required", 'warning');
      return;
    }

    const check_email =  this.user.email.match(/^[-_a-zA-Z0-9]+(?:[!#$%&'*.+=?^`{|}~][-_a-zA-Z0-9]+)*@((?:[-a-zA-Z0-9]+\.)+[a-zA-Z]{2,})$/);

    if (this.mode === 'Add' && !check_email) {
      this.showNotifications('Incorrect Email Address', 'warning')
      return;
    }

    const email_regex = /^[a-zA-Z0-9._%+-]+@verifiedfirst\.com$/;

    if (this.user.role === 'Admin' && !email_regex.test(this.user.email)) {
      this.showNotifications("Incorrect email domain name, Use 'verifiedfirst.com' domain name to create Admin user", 'warning')
      return;
    }

    const email_exist = this.checkEmailExist();

    if (this.mode === 'Add' && email_exist) {
      this.showNotifications('Email address already in use', 'warning');
      return;
    }

    const check_duplication = this.checkUserName();
    
    if (check_duplication) {
      this.showNotifications("User Name already exist", 'warning');
      return;
    }

    const check_user_name = /^[A-Z]+[a-z]*([A-Z]+[a-z]*)*([1-9]+)?$/;

    if (!check_user_name.test(this.user.user_name)) {
      this.showNotifications('User Name should start with an uppercase letter and be followed by lowercase and uppercase letters. You can add 1 to 9 numeric value at the end excluding `+` sign in case User Name duplication', 'warning');
      return;
    }

    if (this.user.password.length < 12 && this.mode === 'Add') {
      this.showNotifications("Password length should be more than or equal to 12", 'warning');
      return;
    }

    if (this.user.password.length < 12 && this.mode === 'Edit' && this.user.role !== 'Admin' && this.change_password) {
      this.showNotifications("Password length should be more than or equal to 12", 'warning');
      return;
    }

    const password_regex = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%&*])[A-Za-z\d@#$%&*]*$/;

    if (!password_regex.test(this.user.password) && this.mode === 'Add') {
      this.showNotifications("Password must include at least one upper case letter ('A-Z'), one lower case letter ('a-z'), one numeric value ('0-9') and one special character value ('@,#,$,%,&,*')", 'warning');
      return;
    }

    if (!password_regex.test(this.user.password) && this.mode === 'Edit' && this.user.role !== 'Admin' && this.change_password) {
      this.showNotifications("Password must include at least one upper case letter ('A-Z'), one lower case letter ('a-z'), one numeric value ('0-9') and one special character value ('@,#,$,%,&,*')", 'warning');
      return;
    }

  }

  checkEmailExist(): boolean {
    return this.user_list.some((u: any) => u.email === this.user.email);
  }

  checkUserName(): boolean {
    if (this.mode === 'Add') {
      return this.user_list.some((u: any) => u.user_name === this.user.user_name);
    }
  
    if (this.mode === 'Edit') {
      return this.user_list.some((u: any) => this.user.id !== u.id && this.user.vpn === u.vpn && this.user.user_name === u.user_name);
    }
  
    return false;
  }

  togglePasswordVisibility(iconElement:any) {
    const passwordInput = document.querySelector('input[name="password"]');
    
    if (passwordInput && iconElement) {
        if (passwordInput.getAttribute('type') === 'password') {
            passwordInput.setAttribute('type', 'text');
            iconElement.classList.remove('fa-eye-slash');
            iconElement.classList.add('fa-eye');
        } else {
            passwordInput.setAttribute('type', 'password');
            iconElement.classList.remove('fa-eye');
            iconElement.classList.add('fa-eye-slash');
        }
    }
  }

  async addMirror() {
    this.spinner.show();

    let params = {
      id: this.user.id,
      is_mirror: this.is_mirror
    };

    try {
      const observable = await this.api.putUser(params);
      const result = await observable.toPromise();

      if (!result) {
        this.spinner.hide();
        this.showNotifications('User validation failed', 'error');
      }

      if (result.status === 200) {
        let message: string = result.message;
        this.reloadHome({ message: message, mode: this.mode });
        this.router.navigate(['/home']);
      } else {
        this.spinner.hide();
        this.showNotifications(result.message, 'error');
      }
    } catch (error: any) {
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }

  async updateUser() {
    this.spinner.show();
    this.validForms();

    if (this.error) {
      this.spinner.hide();
      return;
    }

    this.change_password = false;

    let params = {
      id: this.user.id,
      user_name: this.user.user_name,
      password: this.user.password,
      role: this.user.role,
      vpn: this.user.vpn,
      email: this.user.email
    };

    try {
      const observable = await this.api.putUser(params);
      const result = await observable.toPromise();

      if (!result) {
        this.spinner.hide();
        this.showNotifications('User validation failed', 'error');
      }

      if (result.status === 200) {
        let message: string = result.message;
        this.reloadHome({ message: message, mode: this.mode });
        this.router.navigate(['/home']);
      } else {
        this.spinner.hide();
        this.showNotifications(result.message, 'error');
      }
    } catch (error: any) {
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }
  
  async addUser() {
    this.spinner.show();
    this.validForms();

    if (this.error) {
      this.spinner.hide();
      return;
    }

    this.change_password = false;

    let body = {
      user_name: this.user.user_name || '',
      password: this.user.password || '',
      role: this.user.role || '',
      vpn: this.user.vpn || '',
      email: this.user.email || ''
    };

    try {

      const observable = await this.api.postUser(body);
      const result = await observable.toPromise();

      if (!result) {
        this.spinner.hide();
        this.showNotifications('User validation failed', 'error');
      }

      if (result.status === 200) {
        let message: string = result.message;
        this.reloadHome({ message: message, mode: this.mode });
        this.router.navigate(['/home']);
      } else {
        this.spinner.hide();
        this.showNotifications(result.message, 'error');
      }

    } catch (error: any) {
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }

  reloadHome(user: { message: string; mode: string }) {
    this.addAction.emit({user});
  }

  deny() {
    this.modalClose.emit({value: false});
  }

  showNotifications(message: string, type: string) {
    this.spinner.hide();
    this.error = true;
    this.message = message;
    this.type = type;
    if (this.type == 'error') {
      this.notification.showError(this.message);
    } else if (this.type === 'warning') {
      this.notification.showWarning(this.message)
    } else if (this.type === 'success') {
      this.notification.showSuccess(this.message)
    } else {
      this.notification.showError("An error occurred. Please try again.");
    }

  }

  notificationClose() {
    this.error = false;
  }

}
