import { Component, OnInit, Sanitizer } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfig } from 'src/app/models/config';
import { CognitoService } from '../../services/cognito.service';
import { GetUser } from '../../models/get-user.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {

  user: GetUser = {
    email: '',
    password: ''
  };

  branding_logo: any = '';
  emails: any = [];
  name: string = '';
  error: boolean = false;
  message: string = '';
  type: string = '';
  set_email: string = '';

  constructor(private router: Router,
    private cognitoService: CognitoService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private notification: NotificationService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getDefaultLogo();
    this.authenticateUser();
  }

  public getDefaultLogo() {
    this.apiService.getLogo(AppConfig.s3_base_endpoint + AppConfig.public_bucket_name + "/company_logos/VerifiedFirstLogo.png", "image/png").subscribe(
      (response) => {
        const blobUrl = URL.createObjectURL(response);
        this.branding_logo = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
        this.spinner.hide();
      },
      (error) => {
        this.showNotifications(error.message, 'error');
      }
    );
  }

  public async authenticateUser() {
    const authenticate = await this.cognitoService.isAuthenticated();
    if (authenticate) {
      this.spinner.hide();
      this.router.navigate(['/home']);
    } else {
      this.spinner.hide();
    }
  }

  public async signInClicked() {
    try {
      this.spinner.show();
      this.validUserData();

      if (this.error) {
        this.spinner.hide();
        return;
      }

      const observable = await this.apiService.checkPasswordExpiry(this.user.email);
      const result = await observable.toPromise();

      if (!result) {
        this.spinner.hide();
        this.showNotifications('User Validation failed', 'error');
        return;
      }

      if (result.status === 200) {
        this.spinner.hide();
        this.signIn();
      } else {
        this.spinner.hide();
        this.showNotifications('Your password is expired, please reset your password', 'error');
        return;
      }
    } catch (error: any) {
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }

  public async signIn() {
    this.spinner.show();
    this.validUserData();

    if (this.error) {
      this.spinner.hide();
      return;
    }

    try {
      const result = await this.cognitoService.signIn({
        username: this.user.email,
        password: this.user.password
      });

      if (result && result.isSignedIn && result.nextStep.signInStep === "DONE") {
        this.router.navigate(['/home']);
      } else if (result && !result.isSignedIn && result.nextStep.signInStep === "RESET_PASSWORD") {
        this.spinner.hide();
        this.showNotifications(result.nextStep.signInStep, 'error');
        this.forgetPasswordClicked();
      } else {
        this.spinner.hide();
        this.showNotifications("Something went worng, please contact Verified First support team", 'error');
        return;
      }
    } catch (error: any) {
      console.log("error: ", error)
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }

  public async forgetPasswordClicked() {
    try {
      this.spinner.show();

      if (!this.user.email) {
        this.spinner.hide();
        this.showNotifications('Email Address Required', 'warning')
        return;
      }

      this.emails = [];

      const observable = await this.apiService.getUserEmails();
      const result = await observable.toPromise();

      if (!result) {
        this.spinner.hide();
        this.showNotifications('User Validation Failed', 'error');
      }

      this.emails = JSON.parse(result.body);
      this.checkEmails();

    } catch (error: any) {
      this.spinner.hide();
      this.showNotifications(error.message, 'error');
    }
  }

  checkEmails() {
    this.error = false;

    const email_regex = /^[a-zA-Z0-9._%+-]+@verifiedfirst\.com$/;

    if (!email_regex.test(this.user.email)) {
      this.spinner.hide();
      this.showNotifications("Incorrect email Address", 'error');
      return;
    } else if (!this.emails.includes(this.user.email)) {
      this.spinner.hide();
      this.showNotifications("Invalid Email Address", 'error');
      return;
    } else {
      this.proceedPasswordReset();
    }
  }

  proceedPasswordReset() {
    this.spinner.show();

    if (this.user && this.user.email) {
      this.cognitoService.forgetPassword(this.user.email)
        .then(() => {
          this.setEmail();
          this.spinner.hide();
          this.showNotifications("Confirmation code successfully sent to entered email address", 'success');
          this.router.navigate(['/forget-password']);
        })
        .catch((error: any) => {
          this.spinner.hide();
          this.showNotifications(error.message, 'error');
        });
    } else {
      this.spinner.hide();
      this.showNotifications("Please enter a valid email address", 'warning');
    }
  }

  public setEmail() {
    this.set_email = this.user.email;

    this.apiService.changeValue(this.set_email);
  }

  validUserData() {
    this.error = false;

    if (!this.user.email) {
      this.showNotifications("Email Address Required", 'warning');
      return;
    }

    const check_email = this.user.email.match(/^[-_a-zA-Z0-9]+(?:[!#$%&'*.+=?^`{|}~][-_a-zA-Z0-9]+)*@((?:[-a-zA-Z0-9]+\.)+[a-zA-Z]{2,})$/);

    if (!check_email) {
      this.showNotifications("Incorrect Email Address", 'warning');
    }

    const email_regex = /^[a-zA-Z0-9._%+-]+@verifiedfirst\.com$/;

    if (!email_regex.test(this.user.email)) {
      this.showNotifications("Invalid Email Address", 'warning');
      return;
    }

    if (!this.user.password) {
      this.showNotifications("Password Required", 'warning');
      return;
    }
  }

  togglePasswordVisibility() {
    const passwordInput = document.querySelector('input[name="password"]');
    const eyeIcon = document.querySelector('i.fa');

    if (passwordInput && eyeIcon) {
      if (passwordInput.getAttribute('type') === 'password') {
        passwordInput.setAttribute('type', 'text');
        eyeIcon.classList.remove('fa-eye-slash');
        eyeIcon.classList.add('fa-eye');
      } else {
        passwordInput.setAttribute('type', 'password');
        eyeIcon.classList.remove('fa-eye');
        eyeIcon.classList.add('fa-eye-slash');
      }
    }
  }

  showNotifications(message: string, type: string) {
    this.error = true;
    this.message = message;
    this.type = type;
    if (this.type == 'error') {
      this.notification.showError(this.message);
    } else if (this.type === 'warning') {
      this.notification.showWarning(this.message)
    } else if (this.type === 'success') {
      this.notification.showSuccess(this.message)
    } else {
      this.notification.showError("An error occurred. Please try again.");
    }

  }

  notificationClose() {
    this.error = false;
  }

}
